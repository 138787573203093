import React from 'react';
import { SessionAuth } from 'supertokens-auth-react/recipe/session';

export type SessionAuthProps =
  | ({
      requireAuth?: false;
    } & {
      onSessionExpired?: (() => void);
    })
  | ({
      requireAuth: true;
      redirectToLogin: () => void;
    } & {
      onSessionExpired?: (() => void);
    });

const isWindow = typeof window !== 'undefined';

/**
 * HOC that wraps the component into a SessionAuth.
 * @param sessionAuthProps
 * @returns
 */
export const withSessionAuthContext =
  (sessionAuthProps: SessionAuthProps = {}) =>
  (WrappedComponent: React.ComponentType) => {
    const ComponentWithSession = (props: any) => (
      <SessionAuth {...sessionAuthProps}>
        <WrappedComponent {...props} />
      </SessionAuth>
    );
    return isWindow ? ComponentWithSession : () => null;
  };
