import { Snack } from 'components/Snackbar/Snackbar.entity';
import { AppDispatch } from 'store/store';
import { customSwitch } from 'utils/helpers';
import { apiUser } from '../../services/activateApi';
import ApiRes from '../../services/ApiRes';
import { SET_SNACKBAR } from '../errors/Errors.reducer';
import { SET_IS_LOADING } from '../shared/Shared.reducers';
import { SET_ALL_USERS } from './Admin.reducer';

interface getAllUsersProps {
  page?: number;
  searchStr?: string;
  showUnapproved?: boolean;
  selectedLabel?: string;
  limit?: number;
}
export const getAllUsers = (
  { page, searchStr, showUnapproved = false, selectedLabel, limit }: getAllUsersProps = {
    showUnapproved: false,
  },
) => {
  return async (dispatch: AppDispatch, getState) => {
    dispatch(SET_IS_LOADING(true));

    const {
      adminReducer: { allUsers },
    } = getState();

    if (!page || (page && page > 0 && page <= allUsers.totalPages)) {
      let params: any = { params: { pendingApprobation: showUnapproved, limit: limit } };

      if (page) {
        params = {
          ...params,
          params: {
            page,
            limit,
            pendingApprobation: showUnapproved,
          },
        };
      }

      if (searchStr) {
        params = {
          ...params,
          params: {
            searchStr,
            limit,
            pendingApprobation: showUnapproved,
          },
        };
      }

      if (selectedLabel) {
        params.params.selectedLabel = selectedLabel;
      }
      const res: ApiRes = await apiUser.getAllUsers(params);

      customSwitch(res.status, {
        200: () => {
          dispatch(SET_ALL_USERS(res.data));
        },
        default: () => {
          dispatch(SET_SNACKBAR(new Snack(true, res.error, 'error')));
        },
      });
    }

    dispatch(SET_IS_LOADING(false));
  };
};
