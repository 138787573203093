import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';

import { getMe } from 'store/user/User.actions';
/**
 * Hook to restore user session into the store
 * Needs to be used inside SessionAuthContext (withSessionAuth)
 */
export const useRestoreUserSession = () => {
  const p = useSessionContext();
  const { doesSessionExist } = p;

  const dispatch = useDispatch();

  useEffect(() => {
    if (doesSessionExist) {
      dispatch(getMe());
    }
  }, [dispatch, doesSessionExist]);
};
