export const emailTest =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export function removeTextLayerPdfViewer() {
  // This function will remove this dirty html text overlay of PDF document - but we loose the 'search by text in pdf' function.
  const textLayers = document.querySelectorAll('.react-pdf__Page__textContent');
  textLayers.forEach((layer) => {
    // @ts-expect-error: I know that style exists on textLayer
    const { style } = layer;
    // FROM: https://github.com/wojtekmaj/react-pdf/issues/332
    style.display = 'none';
  });
}

export const customSwitch = (oneCase: number | string | 'default' = 'default', cases: unknown) => {
  const result = cases[oneCase.toString()];
  return (result || cases['default'])();
};

export const isEmailValid = (email: string) => {
  const emailSchema =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return emailSchema.test(String(email).toLowerCase());
};

export const entityFromAlgolia = (indexName: string): 'elearning' | 'documents' | null => {
  if (indexName) {
    if (indexName.includes('course')) return 'elearning';
    if (indexName.includes('document')) return 'documents';
  } else {
    return null;
  }
};
