import React from 'react';

import { useRestoreUserSession } from 'hooks/useRestoreUserSession';

/**
 * HOC that wraps a component which will also restore the user session in to the redux store.
 * @param WrappedComponent
 * @returns
 */
export const withRestoreUserSession = <P,>(WrappedComponent: React.ComponentType<P>) => {
  return (props: P) => {
    useRestoreUserSession();

    return <WrappedComponent {...props} />;
  };
};
