import { SET_ADDITIONAL_RESOURCES, SET_ONEDOCUMENT, SET_ONEDOCUMENT_VERSIONS } from '../documents/Documents.reducers';
import { AppDispatch } from '../store';
import { ModalData } from './Modals.model';
import { SET_CONTENT_MODAL } from './Modals.reducers';

export const setModalContent = (data?: ModalData, refreshData = true) => {
  return async (dispatch: AppDispatch) => {
    dispatch(SET_CONTENT_MODAL(data));
    if (!data && refreshData) {
      dispatch(SET_ONEDOCUMENT(null));
      dispatch(SET_ONEDOCUMENT_VERSIONS(null));
      dispatch(SET_ADDITIONAL_RESOURCES([]));
    }
  };
};
